<template>
  <div class="products">
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('單據') }}</h2>
      </div>
      <div class="invoices-list">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 053126941</h5>
            <p class="card-text">結帳時間： 2024-01-02 13:00:0</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $50</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 142835118</h5>
            <p class="card-text">結帳時間： 2024-01-03 10:00:0</p>
            <p class="card-text">消費菜品： 共2份</p>
            <p class="card-text">實付金額： $120</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 203133739</h5>
            <p class="card-text">結帳時間： 2024-01-03 15:00:0</p>
            <p class="card-text">消費菜品： 共3份</p>
            <p class="card-text">實付金額： $150</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 321451662</h5>
            <p class="card-text">結帳時間： 2024-01-04 12:00:0</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $40</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 457735033</h5>
            <p class="card-text">結帳時間： 2024-01-05 16:00:0</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $35</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 590826851</h5>
            <p class="card-text">結帳時間： 2024-01-05 18:00:0</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $75</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 668187094</h5>
            <p class="card-text">結帳時間： 2024-01-05 18:30:2</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $100</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 765119747</h5>
            <p class="card-text">結帳時間： 2024-01-06 15:00:1</p>
            <p class="card-text">消費菜品： 共2份</p>
            <p class="card-text">實付金額： $120</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 830838515</h5>
            <p class="card-text">結帳時間： 2024-01-07 12:35:8</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $80</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 976703400</h5>
            <p class="card-text">結帳時間： 2024-01-07 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'Seats',
  mounted(){
  },
  data(){
    return {
      seats: [],
    }
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.invoices-list{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  .card{
    margin-bottom: 30px;
    margin-right: 20px;
    .btn-primary{
      margin-right: 30px;
    }
  }
}
</style>
